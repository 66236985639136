import React from "react";
import config from "../config";
// import { useReactOidc } from "@axa-fr/react-oidc-context";
import useAuthorizationHeaders from "../session/useAuthorizationHeaders";
const apiHost = `${config.authority}`;

export function useGet(path) {
  // const { oidcUser } = useReactOidc();
  // const authHeaders = { Authorization: `${oidcUser.token_type} ${oidcUser.access_token}` };

  const authHeaders = useAuthorizationHeaders();

  const [{ data, loading, error }, setState] = React.useState({
    data: null,
    loading: true,
    error: null,
  });
  function runQuery() {
    return fetch(`${apiHost}/${path}`, {
      headers: {
        ...authHeaders,
      },
    })
      .then((r) => r.json())
      .then((r) =>
        setState({
          data: r,
          loading: false,
        })
      )
      .catch((e) =>
        setState({
          loading: false,
          error: e,
        })
      );
  }
  React.useEffect(() => {
    runQuery();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [data, { loading, error, refetch: runQuery }];
}

export function usePost(path) {
  return React.useCallback(
    (body) => {
      return fetch(`${apiHost}/${path}`, {
        method: "POST",
        headers: {
          // ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).catch((x) => console.log("Error encountered POSTing to service" + JSON.stringify(x)));
    },
    [path]
  );
}

export function usePut(path) {
  return React.useCallback(
    (id, body) => {
      return fetch(`${apiHost}/${path}/${id}`, {
        method: "PUT",
        headers: {
          // ...authHeaders,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
    },
    [path]
  );
}

export function useDelete(path) {
  return React.useCallback(
    (id) => {
      return fetch(`${apiHost}/${path}/${id}`, {
        method: "DELETE",
        headers: {
          // ...authHeaders,
          "Content-Type": "application/json",
        },
      });
    },
    [path]
  );
}
