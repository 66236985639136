import React from "react";
import { Checkbox, Progress } from "../components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "203px",
    border: "1px solid #b2b2b1",
    backgroundColor: "#f8f8f8",
    borderRadius: "2px",
    fontFamily: "Lato, sans serif",
    fontSize: "14px",
    height: "32px",
    textTransform: "none",
  },
  paper: {
    backgroundColor: "white",
    position: "absolute",
    marginTop: "10px",
    width: "203px",
    zIndex: "100",
  },
  organisation: {
    padding: "0px 5px",
    display: "flex",
    alignItems: "center",
  },
}));
export default function OrganisationMultiDropdown({
  organisations,
  selectedOrganisations,
  setSelectedOrganisations,
  loading,
  error,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (!loading) {
      setSelectedOrganisations(organisations);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading) {
    return (
      <Button className={classes.button}>
        <Progress />
      </Button>
    );
  }
  if (error) {
    return <Button className={classes.button}>Error</Button>;
  }

  return (
    <div>
      <Button className={classes.button} onClick={() => setOpen(!open)}>
        {selectedOrganisations.length === organisations.length ? (
          "All organisations"
        ) : (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "203px",
              height: "32px",
              paddingTop: "10px",
            }}
          >
            {selectedOrganisations &&
              selectedOrganisations.map((org, index) =>
                index === selectedOrganisations.length - 1 ? org.name : org.name + ", "
              )}
          </div>
        )}
        <div style={{ display: "flex", flexGrow: "1" }} />
        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      </Button>

      {open ? (
        <Paper className={classes.paper} elevation={3}>
          <div className={classes.organisation}>
            <Checkbox
              checked={selectedOrganisations === organisations}
              onClick={() =>
                selectedOrganisations.length === organisations.length
                  ? setSelectedOrganisations([])
                  : setSelectedOrganisations(organisations)
              }
            />
            <div>All organisations</div>
          </div>

          {organisations &&
            organisations
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((organisation, index) => {
                const checked = selectedOrganisations.includes(organisation);

                function handleClick() {
                  if (checked) {
                    if (selectedOrganisations.length === 1) {
                      return;
                    }
                    setSelectedOrganisations(
                      selectedOrganisations.filter((e) => e !== organisation)
                    );
                  } else {
                    setSelectedOrganisations(selectedOrganisations.concat(organisation));
                  }
                }

                return (
                  <div className={classes.organisation} key={index}>
                    <Checkbox checked={checked} onClick={() => handleClick()} />
                    <div>{organisation.name}</div>
                  </div>
                );
              })}
        </Paper>
      ) : null}
    </div>
  );
}
