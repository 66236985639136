import React from "react";
import { DualLogo } from "../components/icons";
import { Link } from "react-router-dom";
import ProfileButton from "./ProfileButton";
export default function Header({ logout }) {
  return (
    <div
      style={{
        padding: "7px 35px",
        display: "flex",
        flexGrow: "1",
        alignItems: "center",
      }}
    >
      <DualLogo style={{ width: "40px", height: "40px", paddingRight: "20px" }} />
      <div
        style={{
          padding: "0px 10px",
          fontWeight: "bold",
          fontSize: "16px",
          cursor: "pointer",
          color: "black",
        }}
        onClick={() =>
          window.location.replace(process.env.REACT_APP_USER_MANAGMENT_PORTAL + "/home")
        }
      >
        Users
      </div>
      <div
        style={{
          padding: "0px 10px",
          fontWeight: "bold",
          fontSize: "16px",
          color: "black",
          cursor: "pointer",
        }}
        onClick={() =>
          window.location.replace(process.env.REACT_APP_USER_MANAGMENT_PORTAL + "/organisation")
        }
      >
        Organisation
      </div>
      <div
        style={{
          padding: "0px 10px",
          fontWeight: "bold",
          fontSize: "16px",
          color: "black",
          cursor: "pointer",
        }}
        onClick={() =>
          window.location.replace(process.env.REACT_APP_USER_MANAGMENT_PORTAL + "/content")
        }
      >
        Content management
      </div>
      <Link
        to={{
          pathname: "/home",
        }}
        style={{ textDecoration: "none" }}
      >
        <div style={{ padding: "0px 10px", fontWeight: "bold", fontSize: "16px", color: "black" }}>
          Reporting
        </div>
      </Link>
      <div style={{ display: "flex", flexGrow: "1" }} />
      <ProfileButton logout={logout} />
    </div>
  );
}
