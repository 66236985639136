/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Profile } from "./icons";
import disclaimer from "../assets/disclaimer.pdf";
import { Paper } from "@material-ui/core";
import Button from "./Button";
export default function ProfileButton({ logout }) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <div onClick={() => setOpen(!open)}>
        <Profile />
      </div>
      {open && (
        <Paper
          elevation={3}
          style={{
            padding: "10px",
            position: "absolute",
            right: "10px",
            zIndex: "1000000",
            top: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ padding: "5px 0px" }}>
            <a
              href="https://mhfaengland.org/privacy-notice.pdf"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Button inverse>Privacy policy</Button>
            </a>
          </div>
          <div style={{ padding: "5px 0px" }}>
            <a href={disclaimer} target="_blank" style={{ textDecoration: "none" }}>
              <Button inverse>Disclaimer</Button>
            </a>
          </div>
          <div style={{ padding: "5px 0px" }}>
            <Button onClick={() => logout()}>Log out</Button>
          </div>
        </Paper>
      )}
    </>
  );
}
