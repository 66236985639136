import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { LineGraph } from "../components";
import { Tooltip } from "@material-ui/core";
import { exportComponentAsPNG } from "react-component-export-image";
import { Download } from "../components/icons";
const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: 13,
    paddingBottom: 20,
    width: 678,
    height: 236,
    background: "#FFFFFF",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
  },
  marginAndPadding: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 21,
    paddingRight: 21,
  },
  title: {
    width: "100%",
    height: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  titleText: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#6F6F6E",
    flex: "none",
    margin: 0,
  },
  summary: {
    height: 43,
    width: 309,
  },
  summaryTextPrimary: {
    fontSize: "36px",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    margin: 0,
    color: "#6F6F6E",
  },
});

export default function LineGraphTile({ title, lineGraphData, loading, error }) {
  const classes = useStyles();
  const componentRef = React.useRef();

  if (loading || !lineGraphData) {
    return (
      <Skeleton
        variant="rect"
        animation="wave"
        width={678}
        height={269}
        style={{ borderRadius: 8 }}
        className={classes.marginAndPadding}
      />
    );
  }
  if (error) {
    return <div className={clsx(classes.root, classes.marginAndPadding)}>Unable to load data.</div>;
  }

  return (
    <div ref={componentRef} className={clsx(classes.root, classes.marginAndPadding)}>
      <div className={classes.title}>
        <p className={classes.titleText}>{title}</p>
        <Tooltip title="Download">
          <div style={{ cursor: "pointer" }} onClick={() => exportComponentAsPNG(componentRef)}>
            <Download />
          </div>
        </Tooltip>
      </div>
      <div>
        <LineGraph title={title} data={lineGraphData} />
      </div>
    </div>
  );
}
