import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  series: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "row",
    margin: "4px",
    marginLeft: "-4px",
    marginRight: "-4px",
  },
  label: {
    fontSize: 14,
    color: "#6F6F6E",
    width: "50%",
    textAlign: "right",
    marginRight: "6px",
  },
  barWrapper: {
    width: "50%",
    marginLeft: "6px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  bar: {
    backgroundColor: "#5FA944",
    borderRadius: "4px",
    flexGrow: 1,
    height: "16px",
  },
  valueLabel: {
    width: "30px",
  },
});

const Series = ({ label, value, domain }) => {
  const classes = useStyles();
  const max = Math.max(...domain);
  const valueLabelWidth = Math.round(((max - value) / max) * 100);

  return (
    <div className={classes.series}>
      <div className={classes.label}>{label}</div>
      <div className={classes.barWrapper}>
        <div className={classes.bar} />
        <div style={{ minWidth: `${valueLabelWidth}%`, paddingLeft: "2px", color: "#5FA944" }}>
          <div className={classes.valueLabel}>{value}</div>
        </div>
      </div>
    </div>
  );
};

function convertTableDataToBarChartSeries(data) {
  const section = data?.rows.filter((row) => {
    return row?.rowType === "Section";
  });

  const result = section[0]?.rows?.map((row) => {
    return { key: row?.cells[0]?.value, value: parseInt(row?.cells[1]?.value) };
  });

  return result
    .sort((a, b) => {
      return a?.value - b?.value;
    })
    .reverse();
}

export default function BarChart({ data }) {
  const classes = useStyles();

  const barChartSeries = convertTableDataToBarChartSeries(data);

  const domain = barChartSeries?.map((item) => {
    return item.value;
  });

  return (
    <div className={classes.root}>
      {barChartSeries?.map((datum) => {
        return <Series key={datum.key} label={datum.key} value={datum.value} domain={domain} />;
      })}
    </div>
  );
}
