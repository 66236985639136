import React from "react";
import { ButtonBase } from "@material-ui/core";
export default function Button({ children, inverse, onClick }) {
  return (
    <ButtonBase
      onClick={onClick}
      style={{
        backgroundColor: inverse ? "white" : "#5FA944",
        color: inverse ? "#5FA944" : "white",
        padding: "8px 20px",
        borderRadius: "2px",
        width: "133px",
        fontFamily: "Lato",
        fontSize: "14px",
        border: inverse ? "1px solid #5FA944" : "1px solid white",
      }}
    >
      {children}
    </ButtonBase>
  );
}
