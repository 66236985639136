import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { AppWrapper } from "./components";
import Home from "./pages/Home";
import SplashScreen from "./pages/SplashScreen";
import { AuthProvider, initUserManager } from "./session/AuthContext";
import useAuthIsLoading from "./session/useAuthIsLoading";
import useLogout from "./session/useLogout";
import useUserIsSuperAdmin from "./session/useUserIsSuperAdmin";

function Router() {
  const isAuthLoading = useAuthIsLoading();
  useUserIsSuperAdmin();
  const logout = useLogout();

  if (isAuthLoading) {
    return <SplashScreen>loading....</SplashScreen>;
  }
  return (
    <AppWrapper>
      <BrowserRouter>
        <Switch>
          <Route exact path="/home" render={(props) => <Home {...props} logout={logout} />} />
          <Redirect from="*" to="/home" />
        </Switch>
      </BrowserRouter>
    </AppWrapper>
  );
}

const oidcConfig = {
  clientId: "reporting_portal",
  redirectUri: `${process.env.REACT_APP_PORTAL_HOST}/authentication/callback`,
  responseType: "code",
  postLogoutRedirectUri: `${process.env.REACT_APP_PORTAL_HOST}`,
  scope: "openid reporting_api IdentityServerApi profile offline_access",
  authority: `${process.env.REACT_APP_API_HOST}/identity`,
  silentRedirectUri: `${process.env.REACT_APP_PORTAL_HOST}/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
};

const userManager = initUserManager(oidcConfig);

function App() {
  return (
    <AuthProvider {...oidcConfig} userManager={userManager}>
      <Router />
    </AuthProvider>
  );
}
export default App;
