import { useGet } from "./api";

const filterOrganisations = (organisations, sectors, locations) => {
  return organisations.filter((org) => sectors.map(s => s.toLowerCase()).includes(org.sector.toLowerCase())
    && locations.map(l => l.toLowerCase()).includes(org.location.toLowerCase()))
}

export function useGetTotalConversationsSummary(
  startDate,
  organisationsFull,
  selectedLocations,
  selectedSectors
) {

  let organisations = organisationsFull
    ? filterOrganisations(organisationsFull, selectedSectors, selectedLocations)
      .map((org) => {
        return org.id;
      })
      .join()
    : "";
  return useGet(
    "reporting/reporting/reports/total-conversations-summary?startDate=" +
    startDate +
    "&organisationIds=" +
    organisations
  );
}

export function useGetActiveUsersSummary(
  startDate,
  organisationsFull,
  selectedLocations,
  selectedSectors
) {
  let organisations = organisationsFull
    ? filterOrganisations(organisationsFull, selectedSectors, selectedLocations)
      .map((org) => {
        return org.id;
      })
      .join()
    : "";
  return useGet(
    "reporting/reporting/reports/active-users-summary?startDate=" +
    startDate +
    "&organisationIds=" +
    organisations
  );
}

export function useGetAverageDurationConversationsSummary(
  startDate,
  organisationsFull,
  selectedLocations,
  selectedSectors
) {
  let organisations = organisationsFull
    ? filterOrganisations(organisationsFull, selectedSectors, selectedLocations)
      .map((org) => {
        return org.id;
      })
      .join()
    : "";
  return useGet(
    "reporting/reporting/reports/average-duration-conversations-summary?startDate=" +
    startDate +
    "&organisationIds=" +
    organisations
  );
}

export function useGetAverageTimeSpentUserSummary(
  startDate,
  organisationsFull,
  selectedLocations,
  selectedSectors
) {
  let organisations = organisationsFull
    ? filterOrganisations(organisationsFull, selectedSectors, selectedLocations)
      .map((org) => {
        return org.id;
      })
      .join()
    : "";
  return useGet(
    "reporting/reporting/reports/average-time-spent-user-summary?startDate=" +
    startDate +
    "&organisationIds=" +
    organisations
  );
}

export function useGetConversationsLoggedLineGraph(
  startDate,
  organisationsFull,
  selectedLocations,
  selectedSectors
) {
  let organisations = organisationsFull
    ? filterOrganisations(organisationsFull, selectedSectors, selectedLocations)
      .map((org) => {
        return org.id;
      })
      .join()
    : "";

  return useGet(
    "reporting/reporting/reports/conversations-logged-line?startDate=" +
    startDate +
    "&organisationIds=" +
    organisations
  );
}

export function useGetActiveUsersLineGraph(
  startDate,
  organisationsFull,
  selectedLocations,
  selectedSectors
) {
  let organisations = organisationsFull
    ? filterOrganisations(organisationsFull, selectedSectors, selectedLocations)
      .map((org) => {
        return org.id;
      })
      .join()
    : "";
  return useGet(
    "reporting/reporting/reports/active-users-line?startDate=" +
    startDate +
    "&organisationIds=" +
    organisations
  );
}

export function useGetSituationFreqTable(
  startDate,
  organisationsFull,
  selectedLocations,
  selectedSectors
) {
  let organisations = organisationsFull
    ? filterOrganisations(organisationsFull, selectedSectors, selectedLocations)
      .map((org) => {
        return org.id;
      })
      .join()
    : "";
  return useGet(
    "reporting/reporting/reports/situation-freq-table?startDate=" +
    startDate +
    "&organisationIds=" +
    organisations
  );
}

export function useGetSignpostFreqTable(
  startDate,
  organisationsFull,
  selectedLocations,
  selectedSectors
) {
  let organisations = organisationsFull
    ? filterOrganisations(organisationsFull, selectedSectors, selectedLocations)
      .map((org) => {
        return org.id;
      })
      .join()
    : "";
  return useGet(
    "reporting/reporting/reports/signpost-freq-table?startDate=" +
    startDate +
    "&organisationIds=" +
    organisations
  );
}

export function useGetOrganisations() {
  return useGet("identity/organisation/all");
}

export function useGetLocations() {
  return useGet("identity/organisation/locations");
}

export function useGetSectors() {
  return useGet("identity/organisation/sectors");
}

/* currently not in use: frequency/table reports */

// export function useGetUsageReport(startDate, endDate) {
//   return useGet(
//     "reporting/reporting/reports/app-usage?startDate=" + startDate + "&endDate=" + endDate
//   );
// }

// export function useGetSignpostingReport(startDate, endDate) {
//   return useGet(
//     "reporting/reporting/reports/situation-freq?startDate=" + startDate + "&endDate=" + endDate
//   );
// }
// export function useGetSituationsReport(startDate, endDate) {
//   return useGet(
//     "reporting/reporting/reports/signpost-freq?startDate=" + startDate + "&endDate=" + endDate
//   );
// }
