import * as React from "react";

export default function OptionsIcon() {
  return (
    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.25 19.5H14.7477C15.1619 19.5 15.4977 19.8358 15.4977 20.25C15.4977 20.6297 15.2155 20.9435 14.8494 20.9932L14.7477 21H1.25C0.835786 21 0.5 20.6642 0.5 20.25C0.5 19.8703 0.782154 19.5565 1.14823 19.5069L1.25 19.5H14.7477H1.25ZM7.88338 1.00233L8 0.995605C8.51284 0.995605 8.93551 1.38165 8.99327 1.87898L9 1.99561V14.5856L12.2941 11.2928C12.6546 10.9324 13.2218 10.9047 13.6141 11.2098L13.7083 11.293C14.0687 11.6535 14.0964 12.2207 13.7913 12.613L13.7081 12.7072L8.71048 17.7038C8.35016 18.064 7.78331 18.0919 7.39103 17.7873L7.29682 17.7042L2.29338 12.7076C1.90259 12.3173 1.90215 11.6842 2.29241 11.2934C2.65265 10.9327 3.21986 10.9045 3.61236 11.2093L3.70662 11.2924L7 14.5806V1.99561C7 1.48277 7.38604 1.0601 7.88338 1.00233L8 0.995605L7.88338 1.00233Z"
        fill="#212121"
      />
    </svg>
  );
}
