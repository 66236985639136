import React from "react";
import { DualLogo } from "../components/icons";

export default function SplashScreen(props) {
  return (
    <div>
      <DualLogo />
    </div>
  );
}
