import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
const useStyles = makeStyles({
  body: {
    border: "0px",
  },
  head: {
    borderBottom: "1px solid #a0a0a0",
  },
  row: {
    border: "0px",
    borderBottom: "1px solid #E0E0E0",
  },
  headerCell: {
    border: "0px",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "0px",
    fontFamily: "Lato, sans-serif",
  },
  cell: {
    border: "0px",
    padding: "15px 20px 15px 10px",
    fontFamily: "Lato, sans-serif",
  },
  lastRow: {
    border: "0px",
  },
  tableTitle: {
    color: "#5FA944",
    fontSize: "20px",
    paddingBottom: "10px",
    fontWeight: "bold",
  },
  headerRow: {
    border: "0px solid black",
    flexDirection: "row",
  },
  unselectedArrow: {
    paddingLeft: "10px",
    width: "16px",
    height: "16px",
    backgroundColor: "green",
    "&:hover": {
      display: "block",
      backgroundColor: "red",
    },
  },
});
function stableSort(array, orderBy, column) {
  const result = array.map((item) => {
    if (item.rowType === "Section") {
      return item.rows.sort(function (a, b) {
        if (isNaN(a.cells[column].value)) {
          if (orderBy === "desc") {
            return a.cells[column].value.localeCompare(b.cells[column].value);
          } else {
            return b.cells[column].value.localeCompare(a.cells[column].value);
          }
        }
        if (orderBy === "desc") {
          return a.cells[column].value - b.cells[column].value;
        } else {
          return b.cells[column].value - a.cells[column].value;
        }
      });
    } else {
      return item;
    }
  });
  return result;
}

export default function FullTable({ data, order, loading }) {
  const classes = useStyles();
  const [orderBy, setOrderBy] = React.useState("asc");
  const [column, setColumn] = React.useState(0);

  React.useEffect(() => {
    if (order && !loading && data?.rows) {
      stableSort(data.rows, orderBy, column);
    }
  }, [loading, order, column, orderBy, data]);

  if (loading) {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
        <Skeleton variant="text" width="50%" height="30px" animation="wave" />
        <div style={{ height: "10px" }} />
        <Skeleton
          variant="rect"
          width="300px"
          height="200px"
          animation="wave"
          style={{ borderRadius: "5px" }}
        />
      </div>
    );
  }

  if (data?.rows) {
    stableSort(data.rows, orderBy, column);
  }
  if (!data) {
    return (
      <div>
        <div className={classes.tableTitle}>Error</div>
        <div
          style={{
            border: "1px solid #a0a0a0",
            borderRadius: "5px",
            overflow: "hidden",
            width: "auto",
          }}
        >
          <Table
            style={{
              width: "auto",
              margin: "0px",
            }}
          >
            <TableHead className={classes.head}>
              <TableRow className={classes.headerRow}>
                <TableCell className={classes.headerCell}>
                  <div style={{ padding: "20px 30px 20px 10px" }}>An error occured</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.body}>
              <TableRow className={classes.row}>
                <TableCell align="left" className={classes.cell}>
                  Or no data was received
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className={classes.tableTitle}>{data?.title}</div>
      <div
        style={{
          border: "1px solid #a0a0a0",
          borderRadius: "5px",
          overflow: "hidden",
          width: "auto",
        }}
      >
        <Table
          style={{
            width: "auto",
            margin: "0px",
          }}
        >
          {data?.rows.map((row, index) => {
            if (row.rowType === "Header") {
              return (
                <TableHead key={index} className={classes.head}>
                  <TableRow className={classes.headerRow}>
                    {row.cells.map((cell, index) => {
                      return (
                        <TableCell
                          className={classes.headerCell}
                          key={index}
                          onClick={() => {
                            if (order) {
                              setColumn(index);
                            }
                          }}
                        >
                          {order ? (
                            <TableSortLabel
                              active={column === index}
                              direction={orderBy}
                              onClick={() => {
                                if (column === index) {
                                  if (orderBy === "asc") {
                                    setOrderBy("desc");
                                  } else {
                                    setOrderBy("asc");
                                  }
                                }
                              }}
                              style={{ padding: "20px 30px 20px 10px" }}
                            >
                              {cell.value}
                            </TableSortLabel>
                          ) : (
                            <div style={{ padding: "20px 30px 20px 10px" }}>{cell.value}</div>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              );
            }
            if (row.rowType === "Section") {
              return (
                <TableBody key={index} className={classes.body}>
                  {row.rows.map((dataRow, index) => {
                    return (
                      <TableRow
                        className={index === row.rows.length - 1 ? classes.lastRow : classes.row}
                        key={index}
                      >
                        {dataRow.cells.map((cell, index) => {
                          return (
                            <TableCell key={index} align="left" className={classes.cell}>
                              {cell.value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              );
            } else {
              return <div></div>;
            }
          })}
        </Table>
      </div>
    </div>
  );
}
