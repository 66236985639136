/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  useGetTotalConversationsSummary,
  useGetActiveUsersSummary,
  useGetAverageDurationConversationsSummary,
  useGetAverageTimeSpentUserSummary,
  useGetActiveUsersLineGraph,
  useGetConversationsLoggedLineGraph,
  useGetSituationFreqTable,
  useGetSignpostFreqTable,
} from "../services/reporting";
import { SummaryTile, LineGraphTile, FrequencyChartTile } from "../components";

export default function ReportsContainer({
  organisations,
  sectors,
  locations,
  startDate,
  selectedOrganisations,
  selectedLocations,
  selectedSectors,
}) {
  const [
    totalConversationsSummaryResponse,
    { loading: loading_tcs, error: error_tcs, refetch: refetch_tcs },
  ] = useGetTotalConversationsSummary(
    startDate.toFormat("yyyy-MM-dd"),
    selectedOrganisations,
    selectedLocations,
    selectedSectors
  );
  const [
    activeUsersSummaryResponse,
    { loading: loading_aus, error: error_aus, refetch: refetch_aus },
  ] = useGetActiveUsersSummary(
    startDate.toFormat("yyyy-MM-dd"),
    selectedOrganisations,
    selectedLocations,
    selectedSectors
  );
  const [
    averageDurationConversationsSummaryResponse,
    { loading: loading_adcs, error: error_adcs, refetch: refetch_adcs },
  ] = useGetAverageDurationConversationsSummary(
    startDate.toFormat("yyyy-MM-dd"),
    selectedOrganisations,
    selectedLocations,
    selectedSectors
  );
  const [
    averageTimeSpentUserSummaryResponse,
    { loading: loading_atsus, error: error_atsus, refetch: refetch_atsus },
  ] = useGetAverageTimeSpentUserSummary(
    startDate.toFormat("yyyy-MM-dd"),
    selectedOrganisations,
    selectedLocations,
    selectedSectors
  );
  const [
    conversationsLoggedLineGraphResponse,
    { loading: loading_cllg, error: error_cllg, refetch: refetch_cllg },
  ] = useGetConversationsLoggedLineGraph(
    startDate.toFormat("yyyy-MM-dd"),
    selectedOrganisations,
    selectedLocations,
    selectedSectors
  );
  const [
    activeUsersLineGraphResponse,
    { loading: loading_aulg, error: error_aulg, refetch: refetch_aulg },
  ] = useGetActiveUsersLineGraph(
    startDate.toFormat("yyyy-MM-dd"),
    selectedOrganisations,
    selectedLocations,
    selectedSectors
  );
  const [
    situationFreqTableResponse,
    { loading: loading_sft, error: error_sft, refetch: refetch_sft },
  ] = useGetSituationFreqTable(
    startDate.toFormat("yyyy-MM-dd"),
    selectedOrganisations,
    selectedLocations,
    selectedSectors
  );
  const [
    signpostFreqTableResponse,
    { loading: loading_spft, error: error_spft, refetch: refetch_spft },
  ] = useGetSignpostFreqTable(
    startDate.toFormat("yyyy-MM-dd"),
    selectedOrganisations,
    selectedLocations,
    selectedSectors
  );
  async function refetchReports() {
    await refetch_tcs();
    await refetch_adcs();
    await refetch_aus();
    await refetch_atsus();
    await refetch_cllg();
    await refetch_aulg();
    await refetch_sft();
    await refetch_spft();
    setInitialLoad(false);
  }
  const [initialLoad, setInitialLoad] = React.useState(true);
  React.useEffect(() => {
    if (organisations && sectors && locations) {
      refetchReports();
    }
  }, [startDate, organisations, selectedOrganisations, selectedSectors, selectedLocations]);

  return (
    <div
      style={{
        display: "flex",
        maxWidth: "1500px",
        flexDirection: "row",
        justifyContent: "center",
        padding: "20px 0px",
        flexWrap: "wrap",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <SummaryTile
          title={totalConversationsSummaryResponse?.title}
          summary={totalConversationsSummaryResponse?.summary}
          loading={loading_tcs || initialLoad}
          error={error_tcs}
        />
        <SummaryTile
          title={activeUsersSummaryResponse?.title}
          summary={activeUsersSummaryResponse?.summary}
          loading={loading_aus || initialLoad}
          error={error_aus}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <SummaryTile
          title={averageDurationConversationsSummaryResponse?.title}
          summary={averageDurationConversationsSummaryResponse?.summary}
          loading={loading_adcs || initialLoad}
          error={error_adcs}
        />
        <SummaryTile
          title={averageTimeSpentUserSummaryResponse?.title}
          summary={averageTimeSpentUserSummaryResponse?.summary}
          loading={loading_atsus || initialLoad}
          error={error_atsus}
        />
      </div>
      <LineGraphTile
        title={conversationsLoggedLineGraphResponse?.title}
        lineGraphData={conversationsLoggedLineGraphResponse?.lineGraph}
        loading={loading_cllg || initialLoad}
        error={error_cllg}
      />
      <LineGraphTile
        title={activeUsersLineGraphResponse?.title}
        lineGraphData={activeUsersLineGraphResponse?.lineGraph}
        loading={loading_aulg || initialLoad}
        error={error_aulg}
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FrequencyChartTile
          title={situationFreqTableResponse?.title}
          tableData={situationFreqTableResponse?.table}
          loading={loading_sft}
          error={error_sft}
        />
        <FrequencyChartTile
          title={signpostFreqTableResponse?.title}
          tableData={signpostFreqTableResponse?.table}
          loading={loading_spft}
          error={error_spft}
        />
      </div>
    </div>
  );
}
