import React from "react";
import { Line } from "@nivo/line";

export default function LineGraph({ title, data }) {
  if (data == null) {
    return <p>No data to display.</p>;
  }

  const dataFormatted = data.data.map((item) => {
    return { x: item.valueX, y: item.valueY };
  });

  const theme = {
    background: "#FFFFFF",
    fontFamily: "Lato",
    text: {
      fill: "#B2B2B1",
    },
    axis: {
      domain: {
        line: {
          stroke: "#6F6F6E",
          strokeWidth: 1,
        },
      },
      fontSize: "14px",
      ticks: {
        line: {
          stroke: "#555555",
        },
        text: {
          fill: "#B2B2B1",
          fontSize: "12px",
          fontFamily: "Lato",
        },
      },
    },
    grid: {
      line: {
        stroke: "rgba(0, 0, 0, 0.04)",
      },
    },
    dots: {
      text: {
        fill: "#5FA944",
        fontSize: "10px",
        marginBottom: 0,
      },
    },
    labels: {
      text: {
        fontFamily: "Lato",
        fill: "#B2B2B1",
      },
    },
  };

  return (
    <Line
      type="point"
      data={[
        {
          id: title,
          data: dataFormatted,
        },
      ]}
      isInteractive={false}
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      animate={true}
      enableGridY={true}
      gridYValues={5}
      height={188}
      width={678}
      xScale={{
        type: "linear",
        min: 1,
        max: "auto",
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 100,
      }}
      axisBottom={{
        tickSize: 0,
        tickValues: dataFormatted.length,
      }}
      axisTop={null}
      axisRight={null}
      colors={["#5FA944", "#ff5e00"]}
      enablePointLabel={true}
      enablePoints
      enableGridX={false}
      pointSize={4}
      pointBorderWidth={1}
      enableArea={true}
      pointBorderColor={"#5FA944"}
      serieColor={"#ffffff"}
      useMesh={true}
      theme={theme}
    />
  );
}
