import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: 13,
    paddingBottom: 13,
    width: 308,
    height: 92,
    background: "#FFFFFF",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
  },
  marginAndPadding: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 21,
    paddingRight: 21,
  },
  title: {
    width: 309,
    height: 24,
  },
  titleText: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#6F6F6E",
    flex: "none",
    margin: 0,
  },
  summary: {
    height: 43,
    width: 309,
  },
  summaryTextPrimary: {
    fontSize: "36px",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "bold",
    margin: 0,
    color: "#6F6F6E",
  },
});

export default function SummaryTile({ title, summary, loading, error }) {
  const classes = useStyles();

  if (loading) {
    return (
      <Skeleton
        variant="rect"
        animation="wave"
        width={308}
        height={118}
        style={{ borderRadius: 8 }}
        className={clsx(classes.marginAndPadding)}
      />
    );
  }
  if (error) {
    return <div className={clsx(classes.root, classes.marginAndPadding)}>Unable to load data.</div>;
  }

  return (
    <div className={clsx(classes.root, classes.marginAndPadding)}>
      <div className={classes.title}>
        <p className={classes.titleText}>{title}</p>
      </div>
      <div className={classes.summary}>
        <p className={classes.summaryTextPrimary}>{summary?.textPrimary}</p>
        <p className={classes.summaryTextSecondary}>{summary?.textSecondary}</p>
      </div>
    </div>
  );
}
