import React from "react";
import { Checkbox, Progress } from "../components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "203px",
    border: "1px solid #b2b2b1",
    backgroundColor: "#f8f8f8",
    borderRadius: "2px",
    fontFamily: "Lato, sans serif",
    fontSize: "14px",
    height: "32px",
    textTransform: "none",
  },
  paper: {
    backgroundColor: "white",
    position: "absolute",
    marginTop: "10px",
    width: "203px",
    zIndex: "100",
  },
  item: {
    padding: "0px 5px",
    display: "flex",
    alignItems: "center",
  },
}));
export default function SectorMultiDropdown({
  selectedSectors,
  sectors,
  setSelectedSectors,
  loading,
  error,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (!loading) {
      setSelectedSectors(sectors);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading) {
    return (
      <Button className={classes.button}>
        <Progress />
      </Button>
    );
  }
  if (error) {
    return <Button className={classes.button}>Error</Button>;
  }
  return (
    <div>
      <Button className={classes.button} onClick={() => setOpen(!open)}>
        {selectedSectors.length === sectors.length ? (
          "All sectors"
        ) : (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "203px",
              height: "32px",
              paddingTop: "10px",
            }}
          >
            {selectedSectors &&
              selectedSectors.map((sector, index) =>
                index === selectedSectors.length - 1 ? sector : sector + ", "
              )}
          </div>
        )}
        <div style={{ display: "flex", flexGrow: "1" }} />
        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      </Button>

      {open ? (
        <Paper className={classes.paper} elevation={3}>
          <div className={classes.item}>
            <Checkbox
              checked={selectedSectors === sectors}
              onClick={() =>
                selectedSectors.length === sectors.length
                  ? setSelectedSectors([])
                  : setSelectedSectors(sectors)
              }
            />
            <div>All sectors</div>
          </div>

          {sectors &&
            sectors.map((sector, index) => {
              const checked = selectedSectors.includes(sector);

              function handleClick() {
                if (checked) {
                  if (selectedSectors.length === 1) {
                    return;
                  }
                  setSelectedSectors(selectedSectors.filter((e) => e !== sector));
                } else {
                  setSelectedSectors(selectedSectors.concat(sector));
                }
              }

              return (
                <div className={classes.item} key={index}>
                  <Checkbox checked={checked} onClick={() => handleClick()} />
                  <div>{sector}</div>
                </div>
              );
            })}
        </Paper>
      ) : null}
    </div>
  );
}
