import React from "react";
import { LeftArrow, RightArrow } from "./icons";

export default function DatePicker({ selectedDate, setSelectedDate }) {
  function addMonth() {
    setSelectedDate(selectedDate.plus({ months: 1 }));
  }

  function subtractMonth() {
    setSelectedDate(selectedDate.minus({ months: 1 }));
  }

  return (
    <>
      <div onClick={() => subtractMonth()}>
        <LeftArrow />
      </div>
      <div
        style={{
          width: "160px",
          height: "24px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          fontFamily: "Lato",
          fontWeight: "bold",
          fontSize: 20,
          color: "#6F6F6E",
          margin: "12px",
          padding: "0px 0px 3px 5px",
        }}
      >
        <p>
          {selectedDate.monthLong.toString()} {selectedDate.year.toString()}
        </p>
      </div>
      <div onClick={() => addMonth()}>
        <RightArrow />
      </div>
    </>
  );
}
