import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";

export default function MCheckbox({ checked, onClick }) {
  const GreenCheckbox = withStyles({
    root: {
      padding: "4px",

      "&$checked": {
        color: green[600],
        padding: "4px",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  return <GreenCheckbox checked={checked} onClick={onClick} />;
}
