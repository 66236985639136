/**
 * Necessary configuration for the open id connect client to connect to the auth provider.
 */
// export default {
//   authority: "https://traefik-448y.onrender.com/identity",
//   client_id: "userportal",
//   response_type: "id_token token",
//   scope: "openid IdentityServerApi",
//   redirect_uri: "http://localhost:3000/login-redirect", //TODO change to render url
//   post_logout_redirect_uri: "http://localhost:3000/logout-redirect", //TODO change to render url
// };

//dev
export default {
  authority: `${process.env.REACT_APP_API_HOST}`,
  client_id: "userportal",
  redirect_uri: `${process.env.REACT_APP_PORTAL_HOST}/login-redirect`,
  response_type: "id_token token",
  scope: "openid IdentityServerApi",
  post_logout_redirect_uri: `${process.env.REACT_APP_PORTAL_HOST}/logout-redirect`,
};
