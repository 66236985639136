import React from "react";
import { DatePicker, Header } from "../components";
import {
  OrganisationsMultiDropdown,
  SectorsMultiDropdown,
  LocationsMultiDropdown,
} from "../containers";
import { DateTime } from "luxon";
import { useGetOrganisations, useGetSectors, useGetLocations } from "../services/reporting";
import ReportsContainer from "../containers/ReportsContainer";

export default function Home({ logout }) {
  const [organisations, { loading: loading_orgs, error: error_orgs }] = useGetOrganisations();
  const [sectors, { loading: loading_sects, error: error_sects }] = useGetSectors();
  const [locations, { loading: loading_locs, error: error_locs }] = useGetLocations();

  const loading = loading_orgs || loading_sects || loading_locs;

  const [startDate, setStartDate] = React.useState(DateTime.local().startOf("month"));
  const [selectedOrganisations, setSelectedOrganisations] = React.useState([]);
  const [selectedSectors, setSelectedSectors] = React.useState([]);
  const [selectedLocations, setSelectedLocations] = React.useState([]);

  function handleSelectedOrganisations(input) {
    setSelectedSectors(sectors);
    setSelectedLocations(locations);
    return setSelectedOrganisations(input);
  }

  function handleSelectedSectors(input) {
    setSelectedOrganisations(organisations);
    return setSelectedSectors(input);
  }

  function handleSelectedLocations(input) {
    setSelectedOrganisations(organisations);
    return setSelectedLocations(input);
  }
  return (
    <div>
      <Header />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "90%",
            display: "flex",
            padding: "40px 0px 24px 0px",
            alignItems: "center",
            borderBottom: "1px solid #B2B2B1",
          }}
        >
          <div style={{ fontSize: "28px", lineHeight: "33px" }}>Reporting</div>
          <div style={{ display: "flex", flexGrow: "1" }} />
          <div style={{ padding: "0px 20px" }}>
            <OrganisationsMultiDropdown
              organisations={organisations}
              selectedOrganisations={selectedOrganisations}
              setSelectedOrganisations={handleSelectedOrganisations}
              loading={loading}
              error={error_orgs}
            />
          </div>
          <div style={{ padding: "0px 20px" }}>
            <SectorsMultiDropdown
              sectors={sectors}
              selectedSectors={selectedSectors}
              setSelectedSectors={handleSelectedSectors}
              loading={loading}
              error={error_sects}
            />
          </div>
          <div style={{ padding: "0px 20px" }}>
            <LocationsMultiDropdown
              locations={locations}
              selectedLocations={selectedLocations}
              setSelectedLocations={handleSelectedLocations}
              loading={loading}
              error={error_locs}
            />
          </div>
          <DatePicker selectedDate={startDate} setSelectedDate={setStartDate} />
        </div>
        <ReportsContainer
          selectedOrganisations={selectedOrganisations}
          startDate={startDate}
          organisations={organisations}
          sectors={sectors}
          locations={locations}
          selectedSectors={selectedSectors}
          selectedLocations={selectedLocations}
        />
      </div>
    </div>
  );
}
