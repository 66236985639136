import React from "react";
import { useAuth } from "./AuthContext";

export default function useAuthorizationHeaders() {
  const auth = useAuth();

  return React.useMemo(() => {
    if (auth.userData) {
      return {
        Authorization: `${auth.userData?.token_type} ${auth.userData?.access_token}`,
      };
    }
    return {};
  }, [auth.userData]);
}
