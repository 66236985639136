import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";

export default function Progress() {
  const GreenProgress = withStyles({
    root: {
      color: green[400],
    },
  })((props) => <CircularProgress {...props} size="20px" />);
  return <GreenProgress />;
}
